.header-action {
    .sidemenu-btn {
        position: relative;
        .btn-wrap {
            background-color: var(--color-light);
        }
        .header-search {
            position: absolute;
            left: -20px;
            input {                
                &:focus {
                    box-shadow: none;
                    border: var(--border-lighter);
                }
                &::placeholder {
                    color: var(--color-dark);
                }
            }
        }
    } 
} 